import * as React from "react"

import AnimationWrapper from "../components/animationWrapper"

import "./contactForm.css"

const ContactForm = ( { subject, name, fields, btnStyle }) => {

  // const [subject,setSelect]= React.useState();

  // const handleCapacity=(e)=>{
  //   setSelect(e.target.value);
  // }

    return (

      <AnimationWrapper>
        <form
          name={name}
          method="POST"
          action="/success"
          data-netlify="true"
          netlify-honeypot="bot-field"
        >
          <div className="form-container">
            <input type="hidden" name="form-name" value={name} />
            <input type="hidden" name="subject" value={subject} />
            <label className="hidden">
              Don’t fill this out if you’re human: <input name="bot-field" />
            </label>
            {fields.includes("name") &&
              <label htmlFor="form-field-name" className="contact-form-label">Imię i nazwisko
                <input type="text" name="name" id="form-field-name" className="contact-form-input" title="* Imię i nazwisko" required="required"></input>
              </label>
            }
            {fields.includes("clientdata") &&
              <label htmlFor="form-field-clientdata" className="contact-form-label">Dane zamawiającego (Imię Nazwisko / Firma) *
                <input type="text" name="clientdata" id="form-field-clientdata" className="contact-form-input" title="* Dane zamawiającego (Imię Nazwisko / Firma)" required="required"></input>
              </label>
            }
            {fields.includes("email") &&
              <label htmlFor="form-field-email" className="contact-form-label">E-mail *
                <input type="email" name="email" id="form-field-email" className="contact-form-input " title="* E-mail" required="required"></input>
              </label>
            }
            {fields.includes("tel") &&
              <label htmlFor="form-field-tel" className="contact-form-label">Nr tel.
                <input type="tel" name="tel" id="form-field-phone" className="contact-form-input " title="Nr tel."></input>
              </label>
            }
            {fields.includes("invoice") &&
              <label htmlFor="form-field-invoice" className="contact-form-label">Nr faktury *
                <input type="tel" name="invoice" id="form-field-invoice" className="contact-form-input " title="* Nr faktury" required="required"></input>
              </label>
            }
            {fields.includes("complain") &&
              <label htmlFor="form-field-complain" className="contact-form-label">Co podlega reklamacji
                <input type="tel" name="complain" id="form-field-complain" className="contact-form-input " title="* Co podlega reklamacji"></input>
              </label>
            }
            {fields.includes("address") &&
              <label htmlFor="form-field-address" className="contact-form-label">Adres budowy
                <input type="tel" name="address" id="form-field-address" className="contact-form-input " title="* Adres budowy"></input>
              </label>
            }
            {fields.includes("message") &&
              <label htmlFor="form-field-message" className="contact-form-label">Wiadomość
                <textarea className="contact-form-textarea" name="message" id="form-field-message" rows="3" required="required" title="* Wiadomość"></textarea>
              </label>
            }
            {fields.includes("description") &&
              <label htmlFor="form-field-description" className="contact-form-label">Opis sytuacji
                <textarea className="contact-form-textarea" name="description" id="form-field-description" rows="7" required="required" title="* Opis sytuacji"></textarea>
              </label>
            }
            <button type="submit" className={`contact-form-submit-btn ${btnStyle}`} id="contact-form-submit-btn">Wyślij</button>
          </div>
        </form>
      </AnimationWrapper>

    )
  }

export default ContactForm